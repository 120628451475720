.contact-us {
  background-color: #17181a;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.contact-us a {
  color: #1e90ff; /* Optional: Change link color to make it stand out */
  text-decoration: none;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #17181a;
}

.contact-us {
  background-color: #17181a;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.contact-us a {
  color: #1e90ff; /* Optional: Change link color to make it stand out */
  text-decoration: none;
}

.contact-us a:hover {
  text-decoration: underline;
}