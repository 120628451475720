.navbar {
  background-color: #232526;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  color: white;
}

.navbar button {
  background-color: #fff;
  color: #232526;
  padding: 10px 20px;
  border: none;
  margin-right: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.navbar button:hover {
  background-color: #ddd;
}

.sub-nav {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #232526;
  color: white;
}

.sub-nav button {
  flex: 1;
  text-align: center;
  color: white;
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sub-nav button:hover {
  background-color: #17b178;
}

.active-link {
  background-color: #17b178;
}