/* MealPrep.css */
.meal-prep {
  background-color: #17181a;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.meal-prep-box {
  background-color: #232526;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.countdown {
  margin-top: 20px;
  font-size: 1.5em;
}