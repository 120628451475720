.contact-us {
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.contact-us h1 {
  color: #222;
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.contact-us h2 {
  color: #444;
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 1em;
}

.contact-us h3 {
  color: #555;
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 0.5em;
}

.contact-us p, .contact-us blockquote {
  font-size: 1em;
  line-height: 1.5;
  max-width: 800px;
  margin: 10px 0;
}

.contact-us blockquote {
  font-style: italic;
  margin: 0.5em 0;
}

.contact-us ul {
  list-style-type: disc;
  margin-left: 20px;
}

.contact-us ul li {
  margin: 5px 0;
}

.contact-us a {
  color: #007bff;
  text-decoration: none;
}

.contact-us a:hover {
  text-decoration: underline;
}