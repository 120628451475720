.terms-of-service {
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  font-family: Arial, sans-serif;
}

.terms-of-service h1 {
  color: #222;
}

.terms-of-service h2 {
  color: #444;
  margin-top: 20px;
}

.terms-of-service h3 {
  color: #555;
  margin-top: 15px;
}

.terms-of-service p {
  margin: 10px 0;
}

.terms-of-service ul {
  list-style-type: disc;
  margin-left: 20px;
}

.terms-of-service ul li {
  margin: 5px 0;
}

.terms-of-service a {
  color: #007bff;
  text-decoration: none;
}

.terms-of-service a:hover {
  text-decoration: underline;
}